<template>
  <!-- 
    Сторінка "Статистика з співробітників"
    - дозволяє переглянути список співробітників та кількість змін ( з пошуком по імені), та з фільтрацією по співробітнику та періоду
  -->

  <!-- Компонент хедеру розділу -->
  <PageHeader :title="title"/>
    
  <!-- 
    Компонент таблиці
    - :dataParams - параметри до кнопки фільтру
    - :objParams - параметри для фільтрації таблиці 
    - @search - подія, яка спрацьовує при пошуку у таблиці
    - :columns - конфігурація стовпців таблиці
    - :rows - масив з даними рядків таблиці
    - :pages - об'єкт із даними про пагінацію таблиці
    - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
    - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
    - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
    - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
    - @clearFilter - подія, яка спрацьовує при очищенні фільтрів таблиці
  -->
  <tablecustom 
    :dataParams="dataParams"
    :objParams="objParams"
    @search="searchB" 
    :columns="columns" 
    :rows="rows"
    :pages="objPages"
    :showpag="true"
    @getdata="getdata"
    @changelimit="changelimit"
    @changeFilter="changeFilter" 
    @clearFilter="clearFilter" 
  />

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import { Statistics } from '@/API' // клас для взаємодії з API 
import { storeS } from "@/store";  // глобальне сховище даних
import { mutateWorkerJob } from "@/usabilityScripts/globalMutate"

let apiServe = new Statistics();

export default {
  components: {
    PageHeader,
    tablecustom,
  },
  data(){
    return{
        title: this.$t('usersStatistics'), // Заголовок
        objPages: '', // Об'єкт з даними про сторінки для розподілу даних по сторінках
        dataParams: { // Параметри для фільтрації таблиці
            status: true, // true - показати кнопку фільтр, false - сховати
            page: "statsByWorkers"
        },
        objParams:{ // Параметри для фільтрації даних
            page: '1',
            pagelimit: '10',
            search: '',
            workerId: '',
            pr1: '',
            pr2: ''
        },
        columns: [
          // Конфігурація колонок таблиці з інформацією про кожну колонку
          // Кожен елемент містить:
          // * name - назву колонки для локалізованого відображення,
          // * text - Ключове слово для отримання значення з даних
          // * align - Вирівнювання
          // * status - Статус відображення колонки
          // * mutate - Функція для мутації значення колонки (переклад)
          // * mutateClass - Функція для мутації класу колонки (стилі)
          {
              name: this.$t('name'),
              text: "workerName",
              align: "left",
              status: true,
          },
          {
              name: this.$t('Position'),
              text: "workerJob",
              align: "left",
              status: true,
              mutate: (item) => this.mutateWorkerJob(item)
          },
          {
              name: this.$t('numberofShifts'),
              text: "shiftsCount",
              align: "left",
              status: true,
          }
        ],
        rows: [], // Дані для відображення у таблиці
    }
  },
  created(){
    this.getdata();
  },
  methods:{
    getdata(page){
        /*
          Функція для отримання даних для таблиці.
          Викликає API для отримання статистики з параметрами з об'єкта objParams.
          Результат присвоюється властивостям rows (список співробітників) та objPages (інформація про сторінки).
        */

        this.objParams.page = page != undefined ? page : '1';
        apiServe.getStatAllusers(this.objParams).then(result => {
            if(result.status == 'done') {
              this.rows = result.data.items
              this.objPages = result.data;
            } else {
              this.$toast.error(this.$t('error') + ` #504`);
            }
        })
    },
    changeFilter(name, value){
      /*
        Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
      */

      if(!name.type && value != undefined){
        if(name == 'pr'){
          this.objParams['pr1'] = value[0];
          this.objParams['pr2'] = value[1];
        } else {
          this.objParams[name] = value;
        }
        this.getdata();
      }
    },
    changelimit: function(limit){
      /*
        Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
      */

      this.objParams.pagelimit = limit;
      this.getdata();
    },
    searchB(e){
      /*
        Функція пошуку по таблиці
        - пошук працює за іменем співробітника
      */

      this.objParams.search = e
      this.getdata();
    },
    mutateWorkerJob(e){
      /*
        Функція для застосування перекладу до посади співробітника
      */
      return mutateWorkerJob(e)
    }
  },
  computed: {
    checks() {
      /*
        Отримуємо дані налаштувань з глобального стору.
      */
      return storeS.checks
    },
  }
}
</script>